import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Box,
  SimpleGrid,
  FormControl,
  Input,
  Textarea,
  FormLabel,
  Select,
} from '@chakra-ui/react'
import { H1, H3 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'
// Newsletter
import Newsletter from '../components/Newsletter'

const ChangeRequest = ({ data }) => {
  const { color } = useContext(ColorContext)

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  return (
    <Layout>
      <SEO title='The Alopex Designers & Developers - Alopex' />
      <Box backgroundColor={color.alpha}>
        <Container maxWidth={breakpoints.xl} pt={spacing.header} pb={spacing.section} color={colors.brand.white}>
          <H1 variant="pageHeader" color={colors.brand.white} mb={spacing.groups}>Change Request Form</H1>
          <form id="changeRequest" name="ChangeRequest" encType="multipart/form-data" method="POST" action="https://getform.io/f/443253c7-4e2b-4603-a2e6-98815a48ac73">
            <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
              <FormControl id="first-name" isRequired>
                <Input type="text" name="First Name" placeholder="First" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="last-name" isRequired>
                <Input type="text" name="Last Name" placeholder="Last" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="email" isRequired>
                <Input name="Email" type="email" placeholder="Email" borderRadius="0" mb="10px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="company" isRequired>
                <Input name="Company Name" type="text" placeholder="Company Name" borderRadius="0" mb="10px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
              <FormControl id="type">
                <FormLabel color={colors.brand.lightGray}>Type of Change:</FormLabel>
                <Select name="Type[]" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} >
                  <option value="Change to Existing Content" name="Change Content">Change to Existing Content</option>
                  <option value="Add New Content" name="Add Content">Add New Content</option>
                </Select>
              </FormControl>
              <FormControl id="multiple-pages">
                <FormLabel color={colors.brand.lightGray}>Should this change be made to multiple pages?</FormLabel>
                <Select name="MultiplePages[]" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} >
                  <option value="No" name="No">No</option>
                  <option value="Yes" name="Yes">Yes</option>
                </Select>
              </FormControl>
            </SimpleGrid>
            <FormControl id="urgency" mb="10px">
                <FormLabel color={colors.brand.lightGray}>Urgency of Change</FormLabel>
                <Select name="urgency[]" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} >
                  <option value="It can wait" name="Wait">It can wait</option>
                  <option value="When you can get to it" name="When you get to it">When you can get to it</option>
                  <option value="Urgent" name="Urgent">Urgent</option>
                  <option value="Extremely Urgent" name="Extremely Urgent">Extremely Urgent</option>
                </Select>
              </FormControl>
              <H3 color={colors.brand.white}>Specific Instructions</H3>
              <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
                <FormControl id="current_content" isRequired>
                  <Textarea name="Current Content" display="block" mb="10px" placeholder="What is the current content?" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
                </FormControl>
                <FormControl id="current_content_photo">
                  <FormLabel color={colors.brand.lightGray}>Upload photos of current content to be changed (if applicable).</FormLabel>
                  <Input className="file-upload" type="file" name="CurrentContent[]" accept=".jpeg,.jpg,.gif,.png,.pdf" multiple/>
                </FormControl>
                <FormControl id="desired_content" isRequired>
                  <Textarea name="Desired Content" display="block" mb="10px" placeholder="What is the desired content?" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
                </FormControl>
                <FormControl id="desired_content_photo">
                  <FormLabel color={colors.brand.lightGray}>Upload photos of desired content to be added (if applicable).</FormLabel>
                  <Input className="file-upload" type="file" name="DesiredContent[]" accept=".jpeg,.jpg,.gif,.png,.pdf" multiple/>
                </FormControl>
            </SimpleGrid>
            <FormControl id="notes">
              <Textarea name="Additional Notes" display="block" mb="10px" placeholder="Additional Notes" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <Input
              type="submit"
              mt="20px"
              value="Send"
              _hover={{
                color: color.alpha,
              }}
            />
          </form>
        </Container>
      </Box>
      <Newsletter title={data.contentJson.title} copy={data.contentJson.newsletterCopy} origin="contact" />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aloRequest {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "contact"}) {
      title
      newsletterCopy
    }
  }
`

export default ChangeRequest
